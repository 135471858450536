import img1 from "./mr-concert-performance-01.jpg";
import img2 from "./mr-concert-performance-02.jpg";
import img3 from "./mr-concert-performance-03.jpg";
import img4 from "./mr-concert-performance-04.jpg";
import img5 from "./mr-concert-performance-05.jpg";
import img6 from "./mr-concert-performance-06.jpg";
import img7 from "./mr-concert-performance-07.jpg";
import img8 from "./mr-concert-performance-08.jpg";
import img9 from "./mr-concert-performance-09.jpg";
import img10 from "./mr-concert-performance-10.jpg";
import img11 from "./mr-concert-performance-11.jpg";
import img12 from "./mr-concert-performance-12.jpg";
import img13 from "./mr-concert-performance-13.jpg";
import img14 from "./mr-concert-performance-14.jpg";
import img15 from "./mr-concert-performance-15.jpg";
import img16 from "./mr-concert-performance-16.jpg";
import img17 from "./mr-concert-performance-17.jpg";
import img18 from "./mr-concert-performance-18.jpg";
import img19 from "./mr-concert-performance-19.jpg";
import img20 from "./mr-concert-performance-20.jpg";
import img21 from "./mr-concert-performance-21.jpg";
import img22 from "./mr-concert-performance-22.jpg";
import img23 from "./mr-concert-performance-23.jpg";
import img24 from "./mr-concert-performance-24.jpg";
import img25 from "./mr-concert-performance-25.jpg";
import img26 from "./mr-concert-performance-26.jpg";
import img27 from "./mr-concert-performance-27.jpg";
import img28 from "./mr-concert-performance-28.jpg";
import img29 from "./mr-concert-performance-29.jpg";
import img30 from "./mr-concert-performance-30.jpg";
import img31 from "./mr-concert-performance-31.jpg";
import img32 from "./mr-concert-performance-32.jpg";
import img33 from "./mr-concert-performance-33.jpg";
import img34 from "./mr-concert-performance-34.jpg";
import img35 from "./mr-concert-performance-35.jpg";

let images = [
  { id: 1, src: img1, title: "", desc: "" },
  { id: 2, src: img2, title: "", desc: "" },
  { id: 3, src: img3, title: "", desc: "" },
  { id: 4, src: img4, title: "", desc: "" },
  { id: 5, src: img5, title: "", desc: "" },
  { id: 6, src: img6, title: "", desc: "" },
  { id: 7, src: img7, title: "", desc: "" },
  { id: 8, src: img8, title: "", desc: "" },
  { id: 9, src: img9, title: "", desc: "" },
  { id: 10, src: img10, title: "", desc: "" },
  { id: 11, src: img11, title: "", desc: "" },
  { id: 12, src: img12, title: "", desc: "" },
  { id: 13, src: img13, title: "", desc: "" },
  { id: 14, src: img14, title: "", desc: "" },
  { id: 15, src: img15, title: "", desc: "" },
  { id: 16, src: img16, title: "", desc: "" },
  { id: 17, src: img17, title: "", desc: "" },
  { id: 18, src: img18, title: "", desc: "" },
  { id: 19, src: img19, title: "", desc: "" },
  { id: 20, src: img20, title: "", desc: "" },
  { id: 21, src: img21, title: "", desc: "" },
  { id: 22, src: img22, title: "", desc: "" },
  { id: 23, src: img23, title: "", desc: "" },
  { id: 24, src: img24, title: "", desc: "" },
  { id: 25, src: img25, title: "", desc: "" },
  { id: 26, src: img26, title: "", desc: "" },
  { id: 27, src: img27, title: "", desc: "" },
  { id: 28, src: img28, title: "", desc: "" },
  { id: 29, src: img29, title: "", desc: "" },
  { id: 30, src: img30, title: "", desc: "" },
  { id: 31, src: img31, title: "", desc: "" },
  { id: 32, src: img32, title: "", desc: "" },
  { id: 33, src: img33, title: "", desc: "" },
  { id: 34, src: img34, title: "", desc: "" },
  { id: 35, src: img35, title: "", desc: "" }
];

export default images;
