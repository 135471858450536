import React from "react";
import images from "../../images/concert-performances/images";

export default () => {
  return (
    <section className="section_padding">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12">
            <div className="section_title text-center">
              <h2>Concerts &amp; Performances</h2>
            </div>
          </div>
        </div>
        <div className="row mb-md-4 mb-2">
          <div className="col-md-12">
            <h3>There are many opportunities for you to perform!</h3>
            <p>
              <ul className="unordered-list">
                <li>Mid Semester Recital (optional) </li>
                <li>Student Concert (compulsory at the end of semester)</li>
                <li>Music Festivals, Jazz Clubs, Special Events, etc.</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row mb-md-4 mb-2">
          <div className="col-md-12">
            <div className="card-columns">
              {images.map(image => (
                <div key={image.id} className="card">
                  <img
                    className="w-100"
                    key={image.id}
                    src={image.src}
                    title={image.id}
                    alt={image.description}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
